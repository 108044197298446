<template>
  <v-container fluid>
    <v-row class="py-4 mx-auto">
      <v-col cols="12">
        <h1>
          <router-link :to="{ name: 'ServiceProvider' }"
            >Service Provider</router-link
          >
          | <span class="title"> Garages</span>
        </h1>
      </v-col>
    </v-row>
    <v-container>
      <v-row class="mt-10">
        <v-col class="mx-auto" cols="12" md="12" xl="11">
          <v-data-table
            :headers="headers"
            :items="garages"
            class="elevation-1"
            :search="search"
            :loading="loadingTable"
          >
            <template v-slot:top>
              <div class="tw-px-2 md:tw-px-6 tw-mb-4">
                <card-title icon="mdi-car" :is-image="false"
                  >Garages</card-title
                >
              </div>

              <v-dialog v-model="dialog" max-width="800px">
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            disabled
                            v-model="editedItem.id"
                            label="id"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Company Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.email"
                            label="Company Email"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.mobile_number"
                            label="Phone Number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.contact_person"
                            label="Contact Person"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.location"
                            label="Location"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-container fluid>
                            <v-row align="center">
                              <v-col>
                                <v-select
                                  v-model="editedItem.regionName"
                                  :items="regions"
                                  label="Select Region"
                                  dense
                                  item-text="name"
                                  item-value="name"
                                  :rules="[required('Region')]"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-col>
                        <v-col class="mt-4" cols="12" sm="6" md="4">
                          <v-switch
                            v-model="editedItem.rare_and_specialized"
                            value
                            label="Rare & Specialised Model"
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-col class="mt-4" cols="12" sm="6" md="4">
                      <v-switch
                        label="Active/Suspended"
                        v-model="status"
                        @change="changeStatus"
                      ></v-switch>
                    </v-col>
                    <v-btn
                      rounded
                      class="ma-5 pa-4"
                      color="secondary"
                      @click="inviteGarage"
                      :loading="loading"
                      :disabled="false"
                    >
                      Invite Vendor
                      <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
                    </v-btn>
                    <v-btn
                      class="error"
                      color="white darken-1"
                      text
                      @click="close"
                      >Cancel</v-btn
                    >
                    <v-btn
                      class="primary"
                      color="white darken-1"
                      text
                      @click="save"
                      >Save</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="showConfirmationDialog" width="400px">
                <v-card>
                  <v-container class="text-center">
                    <v-card-text>
                      <h1>
                        <v-icon x-large color="error" @click="deleteItem(item)">
                          mdi-trash-can-outline
                        </v-icon>
                      </h1>
                      <br />
                      <h3 class="mx-auto primary--text">
                        Confirm Account Deletion
                      </h3>
                      <br />
                      <p>
                        Before you proceed, please be aware that deleting your
                        service provider account is an irreversible prococess.
                        This cannot be undone
                      </p>
                    </v-card-text>
                    <v-card-actions>
                      <div class="ma-auto pb-2">
                        <v-row align="center" justify="space-around">
                          <v-btn outlined @click="closeModal" color="secondary"
                            >CANCEL</v-btn
                          >
                          <v-btn
                            color="secondary"
                            class="ma-5 pa-4"
                            @click="deleteGarage()"
                            :loading="loading"
                          >
                            DELETE
                          </v-btn>
                        </v-row>
                      </div>
                    </v-card-actions>
                  </v-container>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:[`item.rare_and_specialized`]="{ value }">
              <v-switch value :input-value="value" disabled></v-switch>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="tw-flex">
                <v-icon
                  small
                  class="mr-3"
                  color="secondary"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon small color="error" @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <app-invite-vendor :regions="regions" @refreshGarages="fetchGarages" />
  </v-container>
</template>

<script>
import axios from 'axios'
import CardTitle from '@/components/CardTitle'
import Validations from '@/utils/Validations'

export default {
  components: {
    CardTitle,
    appInviteVendor: () =>
      import('@/components/vendor-managment/garage/garageVendorInvite'),
  },
  data: () => ({
    dialog: false,
    loading: false,
    search: '',
    ...Validations,
    headers: [
      {
        text: 'Id',
        align: 'start',
        sortable: true,
        value: 'id',
      },
      {
        text: 'Company Name',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Location',
        align: 'start',
        value: 'location',
      },
      {
        text: 'Contact Person',
        align: 'start',
        sortable: true,
        value: 'contact_person',
      },
      {
        text: 'Phone No.',
        sortable: true,
        value: 'mobile_number',
      },
      {
        text: 'Company Email',
        align: 'start',
        sortable: true,
        value: 'email',
      },
      {
        text: 'Region',
        align: 'start',
        sortable: true,
        value: 'region.name',
      },
      { text: 'Rare Model', value: 'rare_and_specialized' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    regions: [],
    editedIndex: -1,
    updatedGarage: {},
    editedItem: {
      companyName: '',
      location: '',
      contactPerson: '',
      phoneNumber: '',
      companyEmail: '',
      regionName: '',
      rare_and_specialized: '',
      status: '',
    },
    defaultItem: {
      id: '',
      companyName: '',
      location: '',
      contactPerson: '',
      phoneNumber: '',
      companyEmail: '',
      regionName: '',
      model: true,
    },
    garages: [],
    loadingTable: false,
    showConfirmationDialog: false,
    status: false,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  mounted() {
    this.fetchGarages()
    this.fetchRegions()
  },

  methods: {
    async inviteVendor() {
      this.$store.state.loading = true

      const inviteData = {
        name: this.companyName,
        email: this.companyEmail,
        mobile_number: this.phoneNumber,
        contact_person: this.contactPerson,
        location: this.address,
        regionName: this.regionName,
      }
      await this.$store.dispatch('garage/inviteGarageVendor', inviteData)
      this.vendorCancel()
      this.$emit('refreshGarages')
    },
    inviteGarage() {
      this.loading = true
      let data = {
        email: this.editedItem.email,
        name: this.editedItem.name,
        password: this.generatePassword(),
        username: this.editedItem.email,
        phone_number: this.editedItem.mobile_number,
        action: 'invite',
        contact_person: this.editedItem.contact_person,
        garage_roles: 'dashboard:list_bids,dashboard:submit_bid,',
        identifier: this.editedItem.id,
        location: this.editedItem.location,
        region: this.editedItem.region.name,
      }
      axios
        .post('/motor-assessment/api/users/invite/garage', data)
        .then((res) => {
          this.$store.commit('SNACKBAR', {
            state: 'success',
            message: res.data.msg,
            variant: 'success',
          })
          this.loading = false
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.response.data.msg,
            variant: 'error',
          })
          this.loading = false
        })
    },
    fetchGarages() {
      this.loadingTable = true
      axios
        .get('/motor-assessment/api/garages')
        .then((res) => {
          this.garages = res.data.data
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.response.data.msg,
            variant: 'error',
          })
        })
      this.loadingTable = false
    },
    fetchRegions() {
      axios
        .get('/motor-assessment/api/regions')
        .then((response) => {
          this.regions = response.data.data
        })
        .catch(() => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: 'AN Error Occurred, try again',
            variant: 'error',
          })
          this.loading = false
        })
    },
    editItem(item) {
      this.editedIndex = this.garages.indexOf(item)
      this.status = item.active
      this.editedItem = {
        ...{},
        ...item,
        ...{
          regionName: item.region.name,
        },
      }
      this.dialog = true
    },
    deleteItem(item) {
      this.editedIndex = this.garages.indexOf(item)
      this.editedItem = {
        ...{},
        ...item,
        ...{
          regionName: item.region.name,
        },
      }
      this.showConfirmationDialog = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
    save() {
      this.loading = true
      if (this.editedIndex > -1) {
        const formData = {
          ...this.editedItem,
        }
        this.$store.dispatch('garage/updateGarageInfo', formData)
      }
      this.loading = false
      this.close()
    },
    changeStatus() {
      const status = this.status ? 'ACTIVE' : 'SUSPEND'
      axios
        .post(
          `/motor-assessment/api/garages/update-status/${status}/${this.editedItem.id}`
        )
        .then((res) => {
          this.$store.commit('SNACKBAR', {
            state: 'success',
            message: res.data.msg ?? 'Successfull',
            variant: 'success',
          })
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.response.data.msg,
            variant: 'error',
          })
        })
    },
    deleteGarage() {
      this.loading = true
      const status = 'DELETE'
      axios
        .post(
          `/motor-assessment/api/garages/update-status/${status}/${this.editedItem.id}`
        )
        .then((res) => {
          this.$store.commit('SNACKBAR', {
            state: 'success',
            message: res.data.msg ?? 'Garage deleted successfully',
            variant: 'success',
          })
          this.closeModal()
          this.fetchGarages()
          this.loading = false
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.response.data.msg,
            variant: 'error',
          })
          this.loading = false
        })
    },
    generatePassword() {
      let result = ''
      let characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{}|;\':"<>,.?/\\'
      let charactersLength = characters.length

      for (let i = 0; i < 20; i++) {
        const randomIndex = this.secureRandomInteger(0, charactersLength)
        result += characters.charAt(randomIndex)
      }

      return result
    },
    secureRandomInteger(min, max) {
      const range = max - min
      const bytesNeeded = Math.ceil(Math.log2(range) / 8)
      const randomBytes = new Uint8Array(bytesNeeded)
      crypto.getRandomValues(randomBytes)

      const randomValue = randomBytes.reduce(
        (value, byte) => (value << 8) | byte,
        0
      )
      return min + (randomValue % range)
    },
    closeModal() {
      this.showConfirmationDialog = false
    },
  },
}
</script>
